import React, { useState } from 'react'
import { Link } from "gatsby"
import Icon from './icon'

const NAVLINKS = [
    {
        title: 'Home',
        path: '/#home',
        id: 1
    },
    {
        title: 'About me',
        path: '/#about',
        id: 2,
    },
    {
        title: 'Blog',
        path: '#',
        id: 3,
        disabled: true
    },
    {
        title: 'Portfolio',
        path: '/#portfolio',
        id: 4
    },
    {
        title: 'Contact',
        path: '/#contact',
        id: 5
    },
]

export default function Navbar({ mode, currentPage }) {
    const [collapse, setCollapse] = useState(false)

    const hamburgerClick = () => setCollapse(!collapse)


    return (
        <nav className={`navbar navbar--${mode}`} style={{ height: (collapse ? 'auto' : '') }}>
            <div className={`navbar__nav-list-container navbar__nav-list-container--${mode}`}>
                <div className={`navbar__icon-btn-container navbar__icon-btn-container--${mode}`}>
                    <Link to='/'>
                        <Icon />
                    </Link>
                    {mode === 'mobile' ? (
                        <div className='navbar__hamburger-container'>
                            <h4 className='navbar__titleText'>{currentPage}</h4>
                            <button className={`navbar__hamburger navbar__hamburger--${mode}`} onClick={hamburgerClick}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>) : ''}

                </div>
                <ul className={`navbar__nav-list navbar__nav-list--${mode} ${collapse ? 'navbar__nav-list--collapsed' : ''}`}>
                    {NAVLINKS.map(link => {
                        return (
                            link.disabled ? (
                                <Link
                                    key={link.id}
                                    className={`navbar__nav-link navbar__nav-link--${mode} navbar__nav-link--disabled`}
                                    activeClassName={`navbar__nav-link--active-${mode}`}
                                    state={{ activePage: link.title }}
                                    onClick={() => setCollapse(false)}
                                    disabled
                                >
                                    <li className={`navbar__nav-item  navbar__nav-item--${mode}`}>
                                        {link.title}
                                    </li>
                                </Link>
                            ) : (
                                <Link
                                    to={link.path}
                                    key={link.id}
                                    className={`navbar__nav-link navbar__nav-link--${mode}`}
                                    activeClassName={`navbar__nav-link--active-${mode}`}
                                    state={{ activePage: link.title }}
                                    onClick={() => setCollapse(false)}
                                >
                                    <li className={`navbar__nav-item  navbar__nav-item--${mode}`}>
                                        {link.title}
                                    </li>
                                </Link>
                            )
                        )
                    })}
                </ul>
            </div>
        </nav>
    )
}