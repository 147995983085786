import { useState, useEffect } from 'react'

export default function useIndex() {
    const [mode, setMode] = useState('')
    
    const handleResize = () => {
        if (window.innerWidth <= 550) {
            setMode('mobile')
        } else if (window.innerWidth <= 768) {
            setMode('tablet')
        } else {
            setMode('desktop')
        }
    }

    useEffect(() => {
        if (window.innerWidth <= 550) {
            setMode('mobile')
        } else if (window.innerWidth <= 768) {
            setMode('tablet')
        } else {
            setMode('desktop')
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return {
        mode,
        handleResize
    }
}