import React from 'react'
import Icon from "./icon"
import { Link } from 'gatsby'
import Facebook from '../../src/images/social-icons/facebook.svg'
import Twitter from '../../src/images/social-icons/twitter.svg'
import Github from '../../src/images/social-icons/github.svg'
import useIndex from '../hooks/useIndex'


const SOCIALS = [
    {
        title: 'Facebook',
        icon: <Facebook className="footer__social-icon" />,
        url: 'https://facebook.com/hilario.petalbo.96'
    },
    {
        title: 'Github',
        icon: <Github className="footer__social-icon" />,
        url: 'https://github.com/nulldot0'
    },
    {
        title: 'Twitter',
        icon: <Twitter className="footer__social-icon" />,
        url: 'https://twitter.com/HPetalbo'
    }
]

const QUOTES = [
    {
        "attrib": "Steve Jobs",
        "quote": "Design is not just what it looks like and feels like. Design is how it works."
    },
    {
        "attrib": "Steve Jobs",
        "quote": "Innovation distinguishes between a leader and a follower."
    },
    {
        "attrib": "Albert Einstein",
        "quote": "Reality is merely an illusion, albeit a very persistent one."
    },
    {
        "attrib": "Albert Einstein",
        "quote": "If you can't explain it simply, you don't understand it well enough."
    },
    {
        "attrib": "Albert Einstein",
        "quote": "Strive not to be a success, but rather to be of value."
    },
    {
        "attrib": "Steve Jobs",
        "quote": "Your time is limited, so don’t waste it living someone else’s life."
    },
    {
        "attrib": "Bill Gates",
        "quote": "Success is a lousy teacher. It seduces smart people into thinking they can't lose."
    },
    {
        "attrib": "Albert Einstein",
        "quote": "Science without religion is lame, religion without science is blind."
    },
    {
        "attrib": "Henry Ford",
        "quote": "Whether you think you can or you think you can’t, you’re right."
    }
]

const NAVLINKS = [
    {
        title: 'Home',
        url: '/#home'
    },
    {
        title: 'About me',
        url: '/#about'
    },
    {
        title: 'Blog',
        url: '/#',
        disabled: true
    },
    {
        title: 'Portfolio',
        url: '/#portfolio'
    },
    {
        title: 'Contact',
        url: '/#contact'
    },
]

export default function Footer() {
    const { mode } = useIndex()

    const getNavLinks = () => {
        return NAVLINKS.map((item, index) => {
            return (
                item.disabled ? (
                    <Link
                        className={`footer__nav-link footer__nav-link--${mode} footer__nav-link--disabled`}
                        to={!item.disabled && item.url}
                        key={`nav-${index}`}
                        activeClassName={`footer__nav-link--active`}
                        disabled
                    >
                        <li
                            className={`footer__nav-item footer__nav-item--${mode}`}
                        >
                            {item.title}
                        </li>
                    </Link>
                ) : (
                    <Link
                        className={`footer__nav-link footer__nav-link--${mode}`}
                        to={!item.disabled && item.url}
                        key={`nav-${index}`}
                        activeClassName={`footer__nav-link--active`}
                    >
                        <li
                            className={`footer__nav-item footer__nav-item--${mode}`}
                        >
                            {item.title}
                        </li>
                    </Link>
                )

            )
        })
    }

    const getSocialLinks = () => {
        return SOCIALS.map(item => {
            return (
                <a className={`footer__social-link footer__social-link--${mode}`} href={item.url} key={item.title} target="_blank" rel="noreferrer">
                    {item.icon}
                </a>
            )
        })
    }

    const getQuote = () => {
        let qoute = (QUOTES[Math.floor(Math.random() * QUOTES.length)])
        return (
            <blockquote className={`footer__qoute-text footer__qoute-text--${mode}`}>
                {qoute.quote}
                <br />
                <p>-{qoute.attrib}</p>
            </blockquote>
        )
    }

    return (
        <footer className={`footer footer--${mode}`}>
            <div className={`footer__container footer__container--${mode}`}>
                <div className={`footer__brand-icon footer__brand-icon--${mode}`}>
                    <Link to="/">
                        <Icon />
                    </Link>
                </div>
                <div className={`footer__social-quote-container footer__social-quote-container--${mode}`}>
                    {getQuote()}
                    <div className={`footer__socials footer__socials--${mode}`}>
                        <div className={`footer__social-icons footer__social-icons--${mode}`}>{getSocialLinks()}</div>
                        <p className={`footer__center-text footer__center-text--${mode}`}>This website is made with Gatsby JS</p>
                        <small className={`footer__center-text footer__center-text--${mode}`}>Copyright &copy; {new Date().getFullYear()} Hilario C. Petalbo, II. All Rights Reserved</small>
                    </div>
                </div>
                <ul className={`footer__nav-links footer__nav-links--${mode}`}>
                    {getNavLinks()}
                </ul>
            </div>
        </footer>
    )
}