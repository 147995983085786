import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Icon = () => {
    const data = useStaticQuery(graphql`
      query {
        file(relativePath: { eq: "myicon.png" }) {
          childImageSharp {
            # Specify a fixed image and fragment.
            # The default width is 400 pixels
            fixed(width: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `)
    return (
        <Img
            fixed={data.file.childImageSharp.fixed}
        />
    )
}

export default Icon