import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const BgImage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "bg.jpg" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div style={{ position: 'fixed', top: '0', left: '0', zIndex: '-2' }}>
      <div style={
        {
          position: 'fixed', top: '0', 
          left: '0', zIndex: '0',
          height: '100vh', width: '100vw',
          background: '#020202', opacity: '0.9',
        }
      }>
      {/* this is just for background overlay */}
      </div>
      <Img
          fixed={data.file.childImageSharp.fixed}
          alt="Gatsby Docs are awesome"
          style={{ height: '100vh', width: '100vw', zIndex: '-1', }}
        />
    </div>
  )
}

export default BgImage
