import React from 'react'
import Navbar from './navbar'


export default function Header({ currentPage, mode }) {
  return (
    <header>
      <Navbar currentPage={currentPage} mode={mode} />
    </header>
  )
}