import React from 'react'


import Header from "./header"
import BgImage from "../components/bg-image"
import Footer from "./footer"

export default function Layout({ currentPage, mode, children }) {
  return (
    <div>
      <Header currentPage={currentPage} mode={mode} />
      <BgImage />
      <main className={`container container--${mode}`}>{children}</main>
      <Footer />
    </div>
  )
}
